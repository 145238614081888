import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import Allergies from "./../../../assets/Allergies.png";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateAllergies,
  getOneAllergies,
  postPatientAllergies,
  getAllNotifications,
  getUnReadNotifications,
} from "../../../store/actions";
import Add from "../Repeated-Component/Add";

import { useToast } from "@chakra-ui/react";

const SignInSchema = Yup.object().shape({
  value: Yup.string().min(3).required("Required"),
  description: Yup.string().min(3).required("Required"),
});
const AddAllergies = ({
  oneAllergies,
  history,
  updateAllergies,
  getOneAllergies,
  postPatientAllergies,
  setChildData,
  getAllNotifications,
  getUnReadNotifications,
  addedAllergies,
  updatedAllergies
}) => {
  let params = useParams();
  //
  const toast = useToast();
  //
  const [initValue, setInitValue] = useState({
    value: "",
    description: "",
  });

  useEffect(() => {
    if (params.Eid) {
      getOneAllergies(params.Eid);
    }
  }, [params, getOneAllergies]);

  useEffect(() => {
    if (params.Eid && oneAllergies) {
      setInitValue({
        value: oneAllergies.value,
        description: oneAllergies.description,
      });
    }
  }, [oneAllergies, params]);

  const onFormSubmit = (value) => {
    if (params.Eid) {
      return updateAllergies(value, params.Eid, history, toast);
    } else {
      postPatientAllergies(
        value,
        history,
        toast,
        setChildData,
        getAllNotifications,
        getUnReadNotifications
      );
    }
  };

  return (
    <>
      <Add
        Schema={SignInSchema}
        initValue={initValue}
        history={history}
        onFormSubmit={onFormSubmit}
        headerText={`${params.Eid ? "Edit" : "Add "} Allergy`}
        secondHeaderText="Allergy"
        therdHeaderText="Add your Allergy"
        image={Allergies}
        feildsNames={{
          feild1: {
            name: "value",
            type: "text",
            placeholder: "Briefly describe your Allergy",
          },
          feild2: {
            name: "description",
            type: "",
            placeholder: "Describe in as much detail as you can.",
          },
        }}
        setChildData={setChildData}
        btnLoading={addedAllergies.loading || updatedAllergies.loading}

      />
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    oneAllergies: state.oneAllergies.data,
    addedAllergies: state.addAllergies,
    updatedAllergies: state.updateAllergies,
  };
};
export default connect(mapStateToprops, {
  updateAllergies,
  getOneAllergies,
  postPatientAllergies,
  getAllNotifications,
  getUnReadNotifications,
})(AddAllergies);
