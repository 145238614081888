import React, { useEffect, useRef } from "react";
import { Box, Button, Text, useColorMode } from "@chakra-ui/react";
import moment from "moment";
import { checkExt } from "../../../MidicalRecord/Repeated-Component/View";
import {
  checkBgColorMode,
  checkColorMode,
  checkButtonColor,
} from "../../../../global/changeColor";

const MedicalReport = ({ medicalReport, consultation, patient }) => {
  const reportRef = useRef();
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (reportRef && medicalReport) {
      reportRef.current.innerHTML = "";
      reportRef.current.insertAdjacentHTML("beforeend", medicalReport.note);
    }
  }, [reportRef, medicalReport]);

  const handlePrintPDF = () => {
    const newWindow = window.open(medicalReport.generatedFile);

    if (newWindow) {
      // Wait for the new window to fully load the PDF and then call the print function
      newWindow.onload = () => {
        newWindow.focus();
        newWindow.print();
      };
    } else {
      alert("Failed to open the PDF. Please disable your popup blocker.");
    }
  };

  const renderView = () => {
    if (medicalReport) {
      return (
        <div className="my-3">
          <Text
            fontSize="2xl"
            fontWeight="semibold"
            color={checkColorMode(colorMode)}
            className="my-2"
          >
            Medical Report
          </Text>
          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-md-12 p-3 rounded row m-0 my-3 align-items-center"
          >
            <Box className="row m-0 align-items-center">
              <div className="col-md-6">
                <Text fontSize="xl" color={"secondary"}>
                  {medicalReport.title}
                </Text>
                <Text fontSize="xs" color={"smallTextGray"}>
                  {moment
                    .unix(medicalReport.createdAt)
                    .format("DD/MM/YYYY - hh:mm A")}
                </Text>
              </div>
              <div className="col-md-6 row m-0 justify-content-end">
                <Button
                  className="rounded m-2"
                  bg={checkButtonColor(colorMode)}
                  color={checkColorMode(colorMode)}
                  fontSize="14px"
                  minW="140px"
                >
                  <a
                    href={medicalReport.generatedFile}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: colorMode === "dark" ? "#30B8BA" : "#FFF",
                    }}
                  >
                    <span className="me-3">
                      <i className="fas fa-download"></i>
                    </span>{" "}
                    Download
                  </a>
                </Button>
                <Button
                  onClick={handlePrintPDF}
                  className="rounded m-2"
                  bg={"secondary"}
                  color="#fff"
                  fontSize="14px"
                  _hover={{ bg: "secondary_hover" }}
                  minW="140px"
                >
                  <span className="me-4">
                    <i className="fas fa-print"></i>
                  </span>{" "}
                  Print PDF
                </Button>
              </div>
            </Box>

            <Box className="row m-0 align-items-center">
              <Box>
                <Box
                  bg={"smallTextGrayLight"}
                  border={`1px solid `}
                  borderColor="smallTextGrayLightBorder"
                  boxShadow="xs"
                  className="col-12 rounded p-3 mt-3"
                  ref={reportRef}
                ></Box>
                <Box className="p-y mt-3">{checkExt(medicalReport)}</Box>
              </Box>
            </Box>
          </Box>
        </div>
      );
    } else {
      return "nothing to show";
    }
  };

  return <>{renderView()}</>;
};

export default MedicalReport;
