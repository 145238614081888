import React, { useState } from "react";
import { Text, Button, useToast, useColorMode, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import PasswordField from "../../../global/passwordField";
import * as Yup from "yup";
import { changePassword } from "../../../store/actions";
import { connect } from "react-redux";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";

const SubmitSchema = Yup.object().shape({
  oldPassword: Yup.string().min(6).required(),
  password: Yup.string().min(6).required(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(),
});
const ChangePassword = ({ history, changePassword, changedPassword }) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [initValue] = useState({
    oldPassword: "",
    password: "",
    passwordConfirmation: "",
  });
  const onFormSubmit = (value) => {
    const submitVal = {
      oldPassword: value.oldPassword,
      password: value.password,
    };
    changePassword(submitVal, toast, history);
  };

  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="ChangePassword rounded shadow-sm p-4  d-flex flex-wrap"
    >
      <Formik
        initialValues={initValue}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={SubmitSchema}
      >
        <Form className="col-12 row">
          <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
            <Text
              fontSize={{ base: "md", md: "2xl", lg: "4xl" }}
              className="fw-bold my-2"
            >
              {" "}
              Change Password
            </Text>
            <div className="w-100 text-end my-2">
              <Button
                width={"50px"}
                height="50px"
                bg="#f3e9eb"
                _hover={{ bg: "#f3d3da" }}
                fontSize="20px"
                color="#D42C51"
                className="me-2"
              >
                <Link to={"/profile/my_info"} className="nav-link text-error">
                  <i className="fas fa-times"></i>
                </Link>
              </Button>
              <Button
                width={"50px"}
                height="50px"
                bg={checkColorMode(colorMode)}
                _hover={{ bg: checkColorModeHover(colorMode) }}
                fontSize="20px"
                type="submit"
                color="#fff"
                isLoading={changedPassword.loading}
              >
                <i className="fas fa-save"></i>
              </Button>
            </div>
          </div>
          <div className="col-12 mt-2 row">
            <div className="col-md-6 col-12">
              <PasswordField
                label="Old Password"
                placeholder="Old Password"
                name="oldPassword"
              />
            </div>
          </div>
          <div className="col-12 row mt-2">
            <div className="col-md-6 col-12">
              <PasswordField
                label="New Password"
                placeholder="New Password"
                name="password"
              />
            </div>
          </div>
          <div className="col-12 row mt-2">
            <div className="col-md-6 col-12">
              <PasswordField
                label="Re-enter New password"
                placeholder="Re-enter New password"
                name="passwordConfirmation"
              />
            </div>
          </div>
        </Form>
      </Formik>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    changedPassword: state.changePassword,
  };
};
export default connect(mapStateToProps, { changePassword })(ChangePassword);
