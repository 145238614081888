import { useEffect, useState } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
import { ChakraProvider } from "@chakra-ui/react";
import { ColorModeScript } from "@chakra-ui/color-mode";

import NavBar from "./components/NavBar/NavBar";
import Home from "./components/Home/Home";
import Login from "./components/LogIn/Login";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import SignUp from "./components/SignUp/SignUp";
import MidicalRecord from "./components/MidicalRecord/MidicalRecord";
import Consultations from "./components/Consultations/Consultations";
import HospitalRequest from "./components/HospitalRequest";
import MyReading from "./components/MyReadinga/MyReading";
import DeleteAccount from "./components/DeleteAccount/DeleteAccount";

import Profile from "./components/profile/profile";
import SharedProfileData from "./components/SharedProfileData/SharedProfileData";

import { getPatientProfile, getPatientRreadingList } from "./store/actions";

import ProtectedRoutes from "./components/ProtectedRoutes";
import VerifyMobile from "./components/VerifyMobile";
// import VerifyEmail from "./components/VerifyEmail";
import NotFound from "./components/NotFound/NotFound";

import { AnimatePresence } from "framer-motion";
import Notifications from "./components/Notifications/Notifications";
import Footer from "./components/Footer/Footer";
import theme from "./global/Theme";

import { setupNotifications } from "./firebase";
import useVisibilityChange from "./useVisibilityChange ";

function App({ user, getPatientProfile, getPatientRreadingList }) {
  const isForeground = useVisibilityChange();

  const patientId = localStorage.getItem("patientId");
  const token = localStorage.getItem("token");
  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    if (patientId && token) {
      getPatientProfile();
      getPatientRreadingList();
    }
  }, [user, token, patientId, getPatientProfile, getPatientRreadingList]);

  useEffect(() => {
    setupNotifications((message) => {
      if (isForeground) {
        // App is in the foreground, show toast notification
        console.log("noti", message);
      } else {
        // App is in the background, show native notification
        console.log("not noti", message);
      }
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <header>
            <NavBar />
          </header>
          <main className="main">
            <AnimatePresence exitBeforeEnter>
              <Switch>
                <ProtectedRoutes path={"/"} exact component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/login" component={Login} />
                {/* for backward comptability */}{" "}
                <Route path="/register" component={SignUp} />
                <Route path="/signup" component={SignUp} />
                <Route path="/verify_mobile" component={VerifyMobile} />
                {/* <Route path="/verify_email" component={VerifyEmail} /> */}
                <Route
                  exact
                  path="/:patientId/shared-data"
                  component={SharedProfileData}
                />
                <Route
                  exact
                  path="/:medicalRecordType/:patientId/shared-data"
                  component={SharedProfileData}
                />
                <Route
                  exact
                  path="/:medicalRecordType/:patientId/shared-data/:viewType/:viewId"
                  component={SharedProfileData}
                />
                <Route path="/forgetpassword" component={ForgetPassword} />
                <ProtectedRoutes
                  path="/medical-record"
                  component={MidicalRecord}
                />
                <ProtectedRoutes
                  path="/consultations"
                  component={Consultations}
                />
                <ProtectedRoutes path="/my-reading" component={MyReading} />
                <ProtectedRoutes
                  path="/hospital/request/:requestId"
                  component={HospitalRequest}
                />
                <ProtectedRoutes path="/profile" component={Profile} />
                <ProtectedRoutes
                  path="/delete-account"
                  component={DeleteAccount}
                />
                <ProtectedRoutes
                  path="/notifications"
                  component={Notifications}
                />
                <Route path="*" exact={true} component={NotFound} />
              </Switch>
            </AnimatePresence>
          </main>
          <footer>
            <Footer />
          </footer>
        </ChakraProvider>
      </BrowserRouter>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.login,
  };
};
export default connect(mapStateToProps, {
  getPatientProfile,
  getPatientRreadingList,
})(App);
