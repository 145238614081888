import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Avatar, useToast, useColorMode, Text } from "@chakra-ui/react";
import { connect } from "react-redux";
import { postPatientProfilePicture } from "../../store/actions/patientActions";
import { getPatientProfile } from "../../store/actions";
import { checkColorMode } from "../../global/changeColor";
const UploadProfilePic = ({
  postPatientProfilePicture,
  profile,
  getPatientProfile,
}) => {
  const [patientProfile, setPatientProfile] = useState({});
  const toast = useToast();
  const { colorMode } = useColorMode();
  useEffect(() => {
    if (profile) {
      setPatientProfile(profile.data);
    }
  }, [profile]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      postPatientProfilePicture(acceptedFiles, toast).then(() => {
        getPatientProfile();
      });
    },
  });
  return (
    <div {...getRootProps({ className: "" })}>
      <input {...getInputProps()} />
      <div className="avatar">
        <Avatar
          size="xl"
          src={patientProfile ? patientProfile.picture : ""}
          color="#FFF"
        />
        <Text className="camera rounded-circle" bg={checkColorMode(colorMode)}>
          <span>
            <i className="fas fa-camera"></i>
          </span>
        </Text>
      </div>
    </div>
  );
};
// const mapStateToProps = (state) => {
//   return {
//     patient_Profile: state.PatientProfile,
//   };
// };
export default connect(null, { postPatientProfilePicture, getPatientProfile })(
  UploadProfilePic
);
