import { initializeApp } from "firebase/app";

import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBkQX9UzeX08eLpBoUhwdx5Z6l3r24DEnk",
  authDomain: "holdenknight-df392.firebaseapp.com",
  databaseURL: "https://holdenknight-df392.firebaseio.com",
  projectId: "holdenknight-df392",
  storageBucket: "holdenknight-df392.appspot.com",
  messagingSenderId: "1032869607291",
  appId: "1:1032869607291:web:8ba3ceecc1e26756962fde",
  measurementId: "G-YE9J2TYPN0",
};

initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");
      // Get the FCM token
      const token = await getToken(messaging);
      console.log("FCM Token:", token);
    } else {
      console.log("Notification permission denied.");
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log("Foreground Message:", payload);
      // Handle the notification or update your UI
    });
  } catch (error) {
    console.error("Error setting up notifications:", error);
  }
};
export { messaging, setupNotifications };
