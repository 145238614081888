import React from "react";
import Uppy from "@uppy/core";
import { Dashboard, useUppy } from "@uppy/react";
import Tus from "@uppy/tus";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { useHistory } from "react-router-dom";
import { useToast, useColorMode, Text } from "@chakra-ui/react";
import { connect } from "react-redux";
import { updateRadiology } from "../../../store/actions";
import moment from "moment";
import { checkColorMode } from "../../../global/changeColor";

const API_URL = process.env.REACT_APP_API_URL;

function Uploader({ id, updateRadiology, procedureDate, setProcedureDate }) {
  const history = useHistory();
  const toast = useToast();
  const { colorMode } = useColorMode();

  const uppy = useUppy(() => {
    return new Uppy({
      restrictions: {
        allowedFileExtensions: ["dcm"], // Allow only .dcm files
        maxNumberOfFiles: 10, // Optional: limit number of files
      },
      autoProceed: false,
      onBeforeFileAdded: (currentFile) => {
        // Ensure the file has a .dcm extension
        const isDICOM = currentFile.name.toLowerCase().endsWith(".dcm");
        if (isDICOM) {
          if (currentFile.data.webkitRelativePath) {
            currentFile.meta.relativePath = currentFile.data.webkitRelativePath;
          }
          return currentFile;
        } else {
          // Reject any non-DICOM file and notify the user
          toast({
            title: "Invalid file type",
            description: "Only DICOM (.dcm) files are allowed.",
            status: "error",
            duration: 7000,
            isClosable: true,
            position: "top-right",
          });
          return false; // Prevent the file from being added
        }
      },
    })
      .use(Tus, {
        method: "post",
        endpoint: `${API_URL}/patient/radiology/${id}/upload`,
        chunkSize: 2000000,
        headers: function (file) {
          return {
            "X-Api-Key": "du7lik773nspohl0",
            Authorization: `Bearer ${localStorage.token}`,
          };
        },
        formData: true,
        fieldName: "files[]",
      })
      .on("complete", ({ failed, successful }) => {
        if (failed.length) {
          console.log("Upload failed for some files:", failed);
          toast({
            title: "Upload failed",
            description: "Some files could not be uploaded.",
            status: "error",
            duration: 7000,
            isClosable: true,
            position: "top-right",
          });
        }
        if (successful.length) {
          const radiologyItem = localStorage.radiologyItem
            ? JSON.parse(localStorage.radiologyItem)
            : null;
          if (radiologyItem) {
            radiologyItem.procedure_date = moment(new Date()).format("X");
            updateRadiology(radiologyItem, id, toast, history, true);
          }
          if (procedureDate) {
            setProcedureDate(moment(new Date()).format("X"));
          }
          toast({
            title: "Your files uploaded successfully",
            status: "success",
            duration: 7000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  });

  return (
    <div className="col-md-10 col-12">
      <Dashboard
        uppy={uppy}
        proudlyDisplayPoweredByUppy={false}
        showProgressDetails={true}
        // fileManagerSelectionType="both"
        disabled={id ? false : true}
        width="100%"
        className={colorMode === "dark" ? "uppy-dark" : ""}
      />
      <Text color={checkColorMode(colorMode)}>
        Please note only DICOM (.dcm) files are allowed.
      </Text>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { updateRadiology })(Uploader);
