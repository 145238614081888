import React, { useEffect, useRef } from "react";
import {
  Text,
  Button,
  Box,
  Image,
  Avatar,
  useColorMode,
} from "@chakra-ui/react";
import { Link, useLocation, useHistory } from "react-router-dom";
import PDF from "../../../assets/pdf.svg";
import { connect } from "react-redux";
import AlertComponent from "../../../global/Alert.Component";
import { motion } from "framer-motion";

import {
  pageVariants,
  pageTransition,
} from "../../../global/Animations/Animations";

import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";

import moment from "moment";
//to add pdf
export const checkExt = (file) => {
  const generatedFile = file.generatedFile;
  const fileLocation = file.fileLocation;

  if (fileLocation || generatedFile) {
    const startIndex = fileLocation
      ? fileLocation.lastIndexOf(".", fileLocation.length - 1) + 1
      : generatedFile.lastIndexOf(".", generatedFile.length - 1) + 1;
    const checkExt = fileLocation
      ? fileLocation.substring(startIndex)
      : generatedFile.substring(startIndex);
    if (checkExt === "pdf") {
      if (file.fileLocation) {
        return (
          <Box
            justifyContent="center"
            alignItems="center"
            p="5px"
            display="flex"
            flexWrap="wrap"
            boxSize="170px"
            className="rounded pdf-download-wrap"
            position="relative"
          >
            <a
              href={file.fileLocation ? file.fileLocation : file.generatedFile}
              target="_blanck"
            >
              <Box
                w="100%"
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <Image boxSize="130px" src={PDF} alt={"PDF"} />
              </Box>
              {file.fileName ? (
                <Text fontSize="14px" fontWeight="semibold" color="secondary">
                  {file.fileName.substring(0, 15) + "..." || "null"}
                </Text>
              ) : null}
            </a>
            <a
              href={file.fileLocation ? file.fileLocation : file.generatedFile}
              target="_blanck"
            >
              <Box
                className="row justify-content-center align-items-center rounded pdf-download"
                position="absolute"
                color="primary"
                fontSize="2xl"
                fontWeight="bold"
                bg="gray.200"
                top="0"
                bottom="0"
                left="12px"
                right="12px"
              >
                <Box>
                  <Box className="text-center">Download</Box>
                  <Box className="text-center">
                    <span className="">
                      <i className="fas fa-download"></i>
                    </span>
                  </Box>
                </Box>
              </Box>
            </a>
          </Box>
        );
      }
    } else if (
      checkExt === "png" ||
      checkExt === "jpg" ||
      checkExt === "jpeg"
    ) {
      return (
        <Image
          src={file.fileLocation}
          alt={file.fileName}
          border={`1px solid`}
          borderColor="smallTextGrayLightBorder"
          className="rounded"
          boxSize="170px"
        />
      );
    } else {
      return null;
    }
  }
};
//
const View = ({
  headerText,
  params,
  one,
  removeOne,
  linkEdite,
  backLink,
  patient,
  nameForAlert,
  loading,
  error,
  viewer,
}) => {
  const { colorMode } = useColorMode();
  const location = useLocation();
  const history = useHistory();
  const reportRef = useRef();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  useEffect(() => {
    if (reportRef && reportRef.current && one) {
      reportRef.current.innerHTML = "";
      reportRef.current.insertAdjacentHTML(
        "beforeend",
        one.note ? one.note : one.description
      );
    }
  }, [reportRef, one]);

  const showDownloadBtn = (file) => {
    console.log(file);

    const fileName = file.fileName;
    const generatedFile = file.generatedFile;
    const fileLocation = file.fileLocation;
    ////////

    ///////////

    if (fileName || generatedFile || fileLocation) {
      /////
      const startIndex = fileName
        ? fileName.lastIndexOf(".", fileName.length - 1) + 1
        : generatedFile.lastIndexOf(".", generatedFile.length - 1) + 1;
      const checkExt = fileName
        ? fileName.substring(startIndex)
        : generatedFile.substring(startIndex);
      ////////
      if (checkExt === "pdf") {
        return (
          <Button
            className="me-2"
            color="secondary"
            variant="ghost"
            _hover={{
              color: "secondary",
              background: "secondary_hover_Opacity",
            }}
          >
            <a
              href={file.generatedFile || file.fileLocation}
              target="_blank"
              rel="noreferrer"
              style={{ color: "inherit" }}
            >
              <span className="me-3">
                <i className="fas fa-download"></i>
              </span>
              Download
            </a>
          </Button>
        );
      }
    }
  };
  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return "";
    }
    if (one) {
      return (
        <motion.div
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <div className="col-12 d-flex flex-wrap py-4 align-items-center ps-0 justify-content-between">
            <div className="d-flex col-md-6 col-12 ps-0">
              <Button
                onClick={() => history.goBack()}
                className="nav-link h5 me-4 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: "45px", height: "45px" }}
                color={colorMode === "dark" ? "secondary" : "primary"}
                _hover={{
                  color: colorMode === "dark" ? "secondary" : "primary",
                }}
              >
                <i className="fas fa-chevron-left"></i>
              </Button>

              <Text
                fontSize="3xl"
                className="fw-bold"
                color={checkColorMode(colorMode)}
              >
                {headerText}
              </Text>
            </div>

            <div className="col-md-6 col-12 ps-0 text-end">
              {showDownloadBtn(one)}

              {!sharedToken && (
                <>
                  <AlertComponent
                    removeOne={removeOne}
                    nameForAlert={nameForAlert}
                  />
                  <Button
                    as={Link}
                    className="me-2"
                    to={`${linkEdite}/${params.id}`}
                    color={checkColorMode(colorMode)}
                    variant="ghost"
                    _hover={{
                      color: checkColorModeHover(colorMode),
                      background:
                        colorMode === "dark"
                          ? "secondary_hover_Opacity"
                          : "primaryOpacity_hover",
                    }}
                  >
                    <span className="me-3">
                      <i className="fas fa-edit"></i>
                    </span>
                    Edit
                  </Button>
                </>
              )}
            </div>
          </div>
          {viewer && one.procedure_date ? (
            <Box className="col-12 mb-4 d-flex justify-content-end">
              <Button
                size="lg"
                className="ms-auto"
                bg={checkColorMode(colorMode)}
                color="white"
                _hover={{ bg: checkColorModeHover(colorMode) }}
                fontSize="md"
                disabled={one.procedure_date ? false : true}
                pointerEvents={one.procedure_date ? null : "none"}
              >
                <a
                  href={one.viewer_url}
                  target="_blank"
                  className="nav-link text-white"
                  rel="noreferrer"
                >
                  <span className="me-2">
                    <i className="fas fa-eye"></i>
                  </span>{" "}
                  Open medical viewer
                </a>
              </Button>
            </Box>
          ) : null}

          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-12 p-4  rounded shadow-sm"
          >
            <div className="header row m-0 align-items-center">
              <Box className="col-md-6 ">
                <Text
                  className="h4 fw-bold mb-0"
                  color={checkColorMode(colorMode)}
                >
                  {one.title ? one.title : one.value}
                </Text>
                <Text color={"smallTextGray"} fontSize="13px">
                  {moment
                    .unix(one.updated_at || one.updatedAt)
                    .format("DD/MM/YYYY hh:mm A")}
                </Text>
              </Box>

              {one.consultantId ? null : (
                <Box className="col-md-6 text-end d-flex justify-content-end align-items-center">
                  <Text marginRight="5" color="smallTextGray" fontSize="13px">
                    Added By{" "}
                  </Text>
                  <Avatar
                    marginRight="2"
                    src={patient ? patient.picture : null}
                    size="md"
                    name={patient ? patient.firstName : null}
                    color="#FFF"
                  />
                  <Text fontSize="15px" fontWeight="semibold">
                    You{" "}
                  </Text>
                </Box>
              )}
            </div>

            {(one && one.note) || one.description ? (
              <Box
                boxShadow="xs"
                className="rounded p-3 mt-3"
                bg="smallTextGrayLight"
                border={`1px solid`}
                borderColor="smallTextGrayLightBorder"
              >
                <p ref={reportRef}></p>
              </Box>
            ) : null}

            <Box className="p-y mt-3">{checkExt(one)}</Box>
          </Box>
        </motion.div>
      );
    } else {
      return "noting";
    }
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    patient: state.PatientProfile.data,
  };
};
export default connect(mapStateToProps)(View);
