import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import UploadComponent from "./uploadFiles/inputUpload";

import { Button, Text, Box, useColorMode } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";
import { motion } from "framer-motion";
import {
  pageVariants,
  pageTransition,
} from "../../../global/Animations/Animations";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";
const Add = ({
  initValue,
  Schema,
  onFormSubmit,
  headerText,
  secondHeaderText,
  therdHeaderText,
  image,
  feildsNames,
  seeFiles,
  history,
  renderFile,
  setChildData,
  btnLoading,
}) => {
  const { colorMode } = useColorMode();
  return (
    <motion.div
      className="container"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="row justify-content-center">
        <div className="col-12 d-flex py-4 align-items-center">
          {history ? (
            <Button
              onClick={() => history.goBack()}
              className="nav-link h5 me-4 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: "45px", height: "45px" }}
              color={checkColorMode(colorMode)}
              _hover={{ color: colorMode === "dark" ? "secondary" : "primary" }}
            >
              <i className="fas fa-chevron-left"></i>
            </Button>
          ) : null}

          <Text className="h4 fw-bold" color={checkColorMode(colorMode)}>
            {headerText}
          </Text>
        </div>

        <div className="">
          <div className="form row justify-content-center">
            <Formik
              initialValues={initValue}
              enableReinitialize
              validationSchema={Schema}
              onSubmit={onFormSubmit}
            >
              {({ values, setFieldValue, handleBlur, handleChange }) => (
                <Form>
                  <Box
                    className="col-12 p-5 rounded shadow  justify-content-center"
                    bg={checkBgColorMode(colorMode)}
                  >
                    <div className="row justify-content-center">
                      <div className="w-100 d-flex justify-content-center">
                        <img src={image} alt="logo" />
                      </div>

                      <div className="w-100 text-center py-5">
                        <Text
                          color={checkColorMode(colorMode)}
                          className="h3 fw-bold"
                        >
                          {" "}
                          {secondHeaderText}
                        </Text>
                        <Text className="fw-200" color="gray.400" fontSize="sm">
                          {therdHeaderText}
                        </Text>
                      </div>
                    </div>

                    <div className="w-100 d-flex justify-content-center">
                      <div className="w-75 add-wrap">
                        <div className=" mt-2 ">
                          <InputControl
                            type={feildsNames.feild1.type}
                            name={feildsNames.feild1.name}
                            className="rounded"
                            inputProps={{
                              placeholder: feildsNames.feild1.placeholder,
                            }}
                          />
                        </div>
                        <div className="">
                          <Textarea
                            className="mt-3 rounded"
                            value={
                              values.note ? values.note : values.description
                            }
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleBlur(e)}
                            name={feildsNames.feild2.name}
                            placeholder={feildsNames.feild2.placeholder}
                            size="sm"
                          />
                          <ErrorMessage
                            component="div"
                            name={feildsNames.feild2.name}
                            style={{ color: "#d42c51", fontSize: "14px" }}
                            className="mt-1"
                          />
                        </div>
                      </div>
                    </div>
                    {feildsNames.feild3 ? (
                      <>
                        <div className="w-100 d-flex justify-content-center mt-5 flex-wrap">
                          <Text
                            className="w-75 fw-bold my-2 add-wrap"
                            color="gray.400"
                            fontSize="sm"
                          >
                            Add More Files
                          </Text>
                          <UploadComponent
                            setFieldValue={setFieldValue}
                            name={feildsNames.feild3.name}
                            currntFiles={seeFiles}
                            fromMedical={true}
                          />
                          <Text
                            fontSize={"sm"}
                            color={checkColorMode(colorMode)}
                          >
                            please note the file size must be less than or equal
                            200mb
                          </Text>
                          <Text
                            as={ErrorMessage}
                            name={feildsNames.feild3.name}
                            component="div"
                            className="w-75"
                            color="error"
                            fontSize="14px"
                          />
                        </div>
                        <div className="d-flex justify-content-center">
                          <Box className="list-group w-75">
                            {renderFile && renderFile.name ? (
                              <Box
                                className="list-group-item my-2 bg-light-gray rounded d-flex justify-content-between align-items-center py-1"
                                lineHeight="4"
                              >
                                <div className="fs-6 fw-bold d-flex  align-items-center">
                                  <Box
                                    color={checkColorMode(colorMode)}
                                    fontSize={"20px"}
                                    className="me-3"
                                  >
                                    <i className="fa-solid fa-image"></i>
                                  </Box>
                                  <div>
                                    <Box fontSize={"14px"}>
                                      {renderFile.name}
                                    </Box>
                                    {renderFile.size ? (
                                      <Text
                                        color="gray.500"
                                        style={{ fontSize: "11px" }}
                                      >
                                        {renderFile.size} Kb
                                      </Text>
                                    ) : null}
                                  </div>
                                </div>
                                <div>
                                  <Button
                                    onClick={() => {
                                      seeFiles({});
                                      values.files = "";
                                    }}
                                    background="none"
                                    border="none"
                                    color="red.400"
                                    _focus={{
                                      border: "none",
                                      background: "none",
                                    }}
                                    _active={{ background: "red.100" }}
                                  >
                                    <i className="fas fa-times"></i>
                                  </Button>
                                </div>
                              </Box>
                            ) : null}
                          </Box>
                        </div>
                      </>
                    ) : null}
                  </Box>

                  <div className="col-12 m-0 mt-3  row justify-content-between">
                    <div className="col-md-3 my-2">
                      <Button
                        onClick={() => {
                          if (setChildData) {
                            return setChildData(initValue);
                          } else {
                            return history.goBack();
                          }
                        }}
                        colorScheme="red"
                        variant="outline"
                        w="100%"
                        size="lg"
                        fontWeight="semibold"
                        h="56px"
                        _hover={{ color: "red" }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="col-md-3 my-2">
                      <Button
                        type="submit"
                        className="rounded"
                        fontWeight="semibold"
                        size="lg"
                        // isLoading={addedReadiolgy.loading}
                        bg={checkColorMode(colorMode)}
                        color="#fff"
                        _hover={{ bg: checkColorModeHover(colorMode) }}
                        w="100%"
                        h="56px"
                        isLoading={btnLoading}
                      >
                        Save{" "}
                        <span className="ms-4">
                          <i className="fas fa-save"></i>
                        </span>
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Add;
