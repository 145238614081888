const INIT_MEDICAL_STATR = {
  loading: false,
  error: null,
  data: null,
};

export const getMedicalReducer = (
  state = INIT_MEDICAL_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_MEDICAL_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_MEDICAL_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_MEDICAL_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneMedicalReducer = (
  state = INIT_MEDICAL_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_ONE_MEDICAL_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_MEDICAL_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_MEDICAL_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const postMedicalReducer = (
  state = INIT_MEDICAL_STATR,
  { type, payload }
) => {
  switch (type) {
    case "POST_MEDICAL_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_MEDICAL_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_MEDICAL_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const updateMedicalReducer = (
  state = INIT_MEDICAL_STATR,
  { type, payload }
) => {
  switch (type) {
    case "UPDATE_MEDICAL_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_MEDICAL_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_MEDICAL_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const deleteMedicalReducer = (
  state = INIT_MEDICAL_STATR,
  { type, payload }
) => {
  switch (type) {
    case "DELETE_MEDICAL_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_MEDICAL_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "DELETE_MEDICAL_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
